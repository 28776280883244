import React, { ReactNode, useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/asap";
import "@fontsource/cute-font";
import "@fontsource/sarpanch"; // Agency FB
import "@fontsource/stalinist-one";

import {
  AboutTheOrgModal,
  AdsModal,
  ContactInformationModal,
  DomainTypeModal,
  FunctioningLinksModal,
  InformationModalOpener,
  ReferencesModal,
  SpellingErrorsModal,
  WebsiteDateModal
} from "~/components/modals";


interface HasKids {
  children: ReactNode;
}

interface BodyProps extends HasKids {
  title?: string;
}

interface ContainsModal {
  modalFunction: (arg0: boolean) => void;
  modalRating: number;
}

interface ContainsModals {
  modalFunctions: ((arg0: boolean) => void)[];
  modalRatings: number[];
}

export const Title = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div
      className="flex flex-wrap relative lg:flex-no-wrap p-8"
      style={{
        background: "linear-gradient(180deg, #27272A 0%, #D4D4D8 57.81%, #27272A 100%)"
      }}
    >
      <InformationModalOpener
        className="absolute top-0 mt-3 -ml-10"
        rating={modalRatings[0]}
        style={{ left: "100%" }}
        onClick={() => modalFunctions[0](true)}
      />
      <div className="w-full md:w-1/2 lg:w-2/5 flex items-center justify-center">
        <Link className="relative" to="/article/active-life">
          <h1
            className="text-4xl"
            style={{
              fontFamily: "Stalinist One",
              background: "linear-gradient(180deg, #f5d8ad 15%, #d35c22 100%)",
              backgroundClip: "text",
              filter: "drop-shadow(0px 4px 4px #3C531A)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}
          >
            ActiveLife
          </h1>
          <div className="hidden md:block md:absolute bg-red-700 left-0" style={{ clipPath: "polygon(0 0, 0 100%, 100% 50%)", height: "15px", width: "500px", marginLeft: "-200px" }} />
          <div className="hidden md:block md:absolute bg-red-700 left-0 top-14" style={{ clipPath: "polygon(0 0, 0 100%, 100% 50%)", height: "15px", width: "400px", marginLeft: "-200px" }} />
        </Link>
      </div>
      <div className="w-full md:w-1/2 lg:w-3/5 flex items-center justify-center md:justify-left h-100">
        <h2 className="text-active-life-orange-500 text-2xl self-center">Compression Clothes</h2>
      </div>
      <div
        className="hidden md:flex md:absolute space-x-3 items-center justify-center rounded-b-lg z-40"
        style={{
          background: "#94CC33",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
          padding: "30px 50px",
          bottom: "-60px",
          height: "50px",
          left:"60%"
        }}
      >
        <Link to="/article/active-life">HOME</Link>
        <span className="border-2 h-10 border-active-life-orange-900" style={{ width: "2px" }} />
        <Link to="/article/active-life/find-a-retailer">FIND A RETAILER</Link>
        <InformationModalOpener
          rating={modalRatings[1]}
          onClick={() => modalFunctions[1](true)}
        />
      </div>
      <div
        className="block md:hidden flex flex-col space-y-1 items-center justify-center rounded-lg w-full"
        style={{ background: "#94CC33" }}
      >
        <Link to="/article/active-life">HOME</Link>
        <Link to="/article/active-life/find-a-retailer">FIND A RETAILER</Link>
        <InformationModalOpener
          rating={modalRatings[1]}
          onClick={() => modalFunctions[1](true)}
        />
      </div>
    </div>
  );
};

const BodyContent = ({ children }: HasKids): JSX.Element => {
  return (
    <div
      className="py-5"
      style={{
        background: "linear-gradient(90deg, rgba(113,113,121,1) 0%, rgba(228,228,231,1) 25%, rgba(228,228,231,1) 75%, rgba(113,113,121,1) 100%)"
      }}
    >
      {children}
    </div>
  );
};

export const Body = ({ children, title }: BodyProps): JSX.Element => {
  return (
    <div
      className="px-10 min-h-screen"
      style={{
        background: "linear-gradient(180deg, #E2762B 0%, #FAEDD6 100%)"
      }}
    >
      {title &&
        <h2 className="text-black text-4xl text-center font-medium w-full pt-24 mt-0">{title}</h2>
      }
      <BodyContent>
        {children}
      </BodyContent>
    </div>
  );
};

const Socks = (): JSX.Element => {
  return (
    <div
      className="mx-5 py-5 px-10 flex flex-wrap md:flex-nowrap"
      style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        background: "linear-gradient(180deg, #3D738E 0%, #72D2EC 65.62%, #72D2EC 85.94%, #3D738E 100%)"
      }}
    >
      <StaticImage
        alt="Elbow sleeve"
        className="mx-2 rounded"
        height={305}
        layout="fixed"
        loading="eager"
        placeholder="blurred"
        src="../../../images/active-life-elbow.jpg"
        width={458}
      />
      <StaticImage
        alt="Socks"
        className="rounded mt-3 md:mt-0 md:mx-2"
        height={305}
        layout="fixed"
        loading="eager"
        placeholder="blurred"
        src="../../../images/active-life-socks.jpg"
        width={286}
      />
    </div>
  );
};

const Welcome = (): JSX.Element => {
  return (
    <div
      className="mt-8 mx-5 py-8 px-10 leading-tight"
      style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        background: "linear-gradient(180deg, #E27474 0%, #FFFF00 100%)"
      }}
    >
      <p className="text-center">Welcome to your gateway to 21st century exercise apparel. If you’re serious about your diet and training, shouldn’t you also be serious about your exercise wear? These copper-containing cloths can help you run faster and longer. They also help your body recover after exercise. They also help reduce un-wanted odors.</p>
    </div>
  );
};

const Runners = (): JSX.Element => {
  return (
    <div className="w-100 flex justify-center mt-8">
      <StaticImage
        alt="Runners"
        height={374}
        layout="fixed"
        loading="eager"
        placeholder="blurred"
        src="../../../images/active-life-runners.jpg"
        width={513}
      />
    </div>
  );
};

const HowDo = (): JSX.Element => {
  return (
    <div className="bg-active-life-gray-900 flex pt-5 pb-2">
      <span
        className="ml-10 text-xl text-active-life-orange-400 tracking-tight"
        style={{
          fontFamily: "Sarpanch",
          textShadow: "0px 4px 4px #FFFFFF"
        }}
      >
        HOW DO ACTIVE LIFE COMPRESSION CLOTHES WORK?
      </span>
    </div>
  );
};

const BacteriaAndFungi = ({ modalFunction, modalRating }: ContainsModal): JSX.Element => {
  return (
    <div className="relative bg-white md:ml-5 py-2 px-5 md:max-w-2xl">
      <p className="tracking-tight text-sm">Bacteria and fungi are rapidly killed when placed on a metallic copper surface. In the lab, experiments have shown that microbes are eliminated when exposed to copper plates for an extended time. The U.S. Environmental Protection Agency has registered copper as the first solid antimicrobial material. Copper also helps to release positive ions that are associated with health and wellness.</p>
      <p className="mt-5 tracking-tight text-sm">Grass, G., C. Rensing, M. Solioz.  Metallic Copper as an Antimicrobial Surface. Applied and Environmental Microbiology. 2011;77(5):1541-1547.</p>
      <InformationModalOpener
        className="absolute bottom-0 -ml-5"
        rating={modalRating}
        style={{ left: "100%" }}
        onClick={() => modalFunction(true)}
      />
    </div>
  );
};

const HowCan = ({ modalFunction, modalRating }: ContainsModal): JSX.Element => {
  return (
    <div className="flex justify-center w-full">
      <div
        className="relative pt-3 pb-1 px-1"
        style={{
          background: "linear-gradient(180deg, #2C4D62 0%, #BBF2FB 58.33%)",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }}
      >
        <InformationModalOpener
          className="absolute right-0 top-0 mt-3 -mr-10"
          rating={modalRating}
          onClick={() => modalFunction(true)}
        />
        <p className="text-3xl text-active-life-gray-500" style={{ fontFamily: "Cute Font" }}>HOW CAN I GET MY ACTIVE LIFE COMPRESSION CLOTHS?</p>
      </div>
    </div>
  );
};

const FindARetailer = (): JSX.Element => {
  return (
    <div className="bg-white md:ml-5 pt-2 pb-12 px-5 md:max-w-3xl">
      <p className="tracking-tight text-sm">Active Life Compression Clothes are sold by hundreds of retailers through out the United States. Use the <span className="font-bold">Find a retailer link</span> to find a store near you.</p>
    </div>
  );
};

const FirstTimeBuyers = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div className="relative">
      <InformationModalOpener
        className="absolute left-0 top-0 -ml-10 mt-10 md:ml-24 md:mt-20"
        rating={modalRatings[0]}
        onClick={() => modalFunctions[0](true)}
      />
      <InformationModalOpener
        className="absolute top-0 -ml-5 mt-10 md:-ml-20 md:mt-32"
        rating={modalRatings[1]}
        onClick={() => modalFunctions[1](true)}
        style={{ left: "100%" }}
      />
      <div
        className="hidden md:block rounded-full w-1/2 mt-8 mb-24 ml-80"
        style={{
          background: "radial-gradient(50% 50% at 50% 50%, #FFF 10%, #E80404 100%)",
          boxShadow: "0px 4px 4px 8px rgba(0, 0, 0, 0.25)",
          transform: "rotate(-14.27deg)"
        }}
      >
        <div className="flex flex-wrap lg:flex-no-wrap items-center justify-center w-full py-5">
          <p className="font-bold text-2xl">FIRST TIM BUYERS GET <span className="text-4xl">30% OFF!</span></p>
        </div>
      </div>
      <div className="block md:hidden w-full mt-8 bg-red-700 px-5">
        <div className="flex items-center justify-center w-full py-5">
          <p className="font-bold text-2xl">FIRST TIM BUYERS GET <span className="text-4xl">30% OFF!</span></p>
        </div>
      </div>
    </div>
  );
};

const HomeContent = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <Body>
      <Socks />
      <Welcome />
      <Runners />
      <HowDo />
      <BacteriaAndFungi modalFunction={modalFunctions[1]} modalRating={modalRatings[1]} />
      <HowCan modalFunction={modalFunctions[3]} modalRating={modalRatings[3]} />
      <FindARetailer />
      <FirstTimeBuyers modalFunctions={[modalFunctions[0], modalFunctions[2]]} modalRatings={[modalRatings[0], modalRatings[2]]} />
    </Body>
  );
};

export const Footer = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div className="bg-active-life-gray-900 flex flex-wrap py-2 md:flex-nowrap">
      <div className="flex items-center justify-center w-full md:w-3/4">
        <span className="text-white text-2xl font-semibold relative" style={{ fontFamily: "Asap" }}>
          <InformationModalOpener
            className="absolute left-0 top-0 -ml-12"
            rating={modalRatings[0]}
            onClick={() => modalFunctions[0](true)}
          />
          www.actlifecloth.com
        </span>
      </div>
      <div className="relative flex items-center justify-center w-full md:w-1/4">
        <InformationModalOpener
          className="hidden md:block absolute left-0 top-0 -ml-12"
          rating={modalRatings[1]}
          onClick={() => modalFunctions[1](true)}
        />
        <span className="text-active-life-gray-600 text-xs">Last updated on February 6, 2020</span>
        <InformationModalOpener
          className="block md:hidden absolute top-0 -ml-12 -mt-5"
          rating={modalRatings[1]}
          style={{ left: "100%" }}
          onClick={() => modalFunctions[1](true)}
        />
      </div>
    </div>
  );
};

const ActiveLifeHomePage = (): JSX.Element => {
  const [domainTypeModalVisible, setDomainTypeModalVisible] = useState<boolean>(false);
  const [domainTypeModalRating, setDomainTypeModalRating] = useState<number>(-1);
  const [aboutTheOrgModalVisible, setAboutTheOrgModalVisible] = useState<boolean>(false);
  const [aboutTheOrgModalRating, setAboutTheOrgModalRating] = useState<number>(-1);
  const [websiteDateModalVisible, setWebsiteDateModalVisible] = useState<boolean>(false);
  const [websiteDateModalRating, setWebsiteDateModalRating] = useState<number>(-1);
  const [referencesModalVisible, setReferencesModalVisible] = useState<boolean>(false);
  const [referencesModalRating, setReferencesModalRating] = useState<number>(-1);
  const [contactInformationModalVisible, setContactInformationModalVisible] = useState<boolean>(false);
  const [contactInformationModalRating, setContactInformationModalRating] = useState<number>(-1);
  const [functioningLinksModalVisible, setFunctioningLinksModalVisible] = useState<boolean>(false);
  const [functioningLinksModalRating, setFunctioningLinksModalRating] = useState<number>(-1);
  const [adsModalVisible, setAdsModalVisible] = useState<boolean>(false);
  const [adsModalRating, setAdsModalRating] = useState<number>(-1);
  const [spellingErrorsModalVisible, setSpellingErrorsModalVisible] = useState<boolean>(false);
  const [spellingErrorsModalRating, setSpellingErrorsModalRating] = useState<number>(-1);

  return (
    <>
      <Title modalFunctions={[setContactInformationModalVisible, setFunctioningLinksModalVisible]} modalRatings={[contactInformationModalRating, functioningLinksModalRating]} />
      <HomeContent
        modalFunctions={[setAboutTheOrgModalVisible, setReferencesModalVisible, setAdsModalVisible, setSpellingErrorsModalVisible]}
        modalRatings={[aboutTheOrgModalRating, referencesModalRating, adsModalRating, spellingErrorsModalRating]}
      />
      <Footer modalFunctions={[setDomainTypeModalVisible, setWebsiteDateModalVisible]} modalRatings={[domainTypeModalRating, websiteDateModalRating]} />
      {domainTypeModalVisible && (
        <DomainTypeModal
          modalRating={domainTypeModalRating}
          modalVisible={domainTypeModalVisible}
          setModalRating={setDomainTypeModalRating}
          setModalVisible={setDomainTypeModalVisible}
        />
      )}
      {aboutTheOrgModalVisible && (
        <AboutTheOrgModal
          modalRating={aboutTheOrgModalRating}
          modalVisible={aboutTheOrgModalVisible}
          setModalRating={setAboutTheOrgModalRating}
          setModalVisible={setAboutTheOrgModalVisible}
        />
      )}
      {websiteDateModalVisible && (
        <WebsiteDateModal
          modalRating={websiteDateModalRating}
          modalVisible={websiteDateModalVisible}
          setModalRating={setWebsiteDateModalRating}
          setModalVisible={setWebsiteDateModalVisible}
        />
      )}
      {referencesModalVisible && (
        <ReferencesModal
          modalRating={referencesModalRating}
          modalVisible={referencesModalVisible}
          setModalRating={setReferencesModalRating}
          setModalVisible={setReferencesModalVisible}
        />
      )}
      {contactInformationModalVisible && (
        <ContactInformationModal
          modalRating={contactInformationModalRating}
          modalVisible={contactInformationModalVisible}
          setModalRating={setContactInformationModalRating}
          setModalVisible={setContactInformationModalVisible}
        />
      )}
      {functioningLinksModalVisible && (
        <FunctioningLinksModal
          modalRating={functioningLinksModalRating}
          modalVisible={functioningLinksModalVisible}
          setModalRating={setFunctioningLinksModalRating}
          setModalVisible={setFunctioningLinksModalVisible}
        />
      )}
      {adsModalVisible && (
        <AdsModal
          modalRating={adsModalRating}
          modalVisible={adsModalVisible}
          setModalRating={setAdsModalRating}
          setModalVisible={setAdsModalVisible}
        />
      )}
      {spellingErrorsModalVisible && (
        <SpellingErrorsModal
          modalRating={spellingErrorsModalRating}
          modalVisible={spellingErrorsModalVisible}
          setModalRating={setSpellingErrorsModalRating}
          setModalVisible={setSpellingErrorsModalVisible}
        />
      )}
    </>
  )
};

export default ActiveLifeHomePage;

